<template>

  <div>
    <!--event-list-add-new
      :is-add-new-event-sidebar-active.sync="isAddNewEventSidebarActive"
      :machine-options="machineOptions"
      :company-options="companyOptions"
      @refetch-data="refetchData"
    /-->

    <!-- Filters -->
    <events-list-filters
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"

      :object-filter.sync="objectFilter"
      :object-options="objectOptions"

      :machine-filter.sync="machineFilter"
      :machine-options="machineOptions"

      :range-picker.sync="rangePicker"
    />
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Events') }}</span>
        </template>
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('common.search')"
                  />
                  <!--b-button
                      variant="primary"
                      @click="isAddNewEventSidebarActive = true"
                    >
                      <span class="text-nowrap">Add Event</span>
                    </b-button-->

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item @click="fetchEventsFile()">
                      <feather-icon icon="AlignJustifyIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_xlsx') }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="true"
                      @click="fetchSalesFile('pdf')"
                    >
                      <feather-icon icon="BookIcon" />
                      <span class="align-middle ml-50">{{ $t('common.save_pdf') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>

                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refEventListTable"
            class="position-relative"
            :items="fetchEvents"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Event -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <b-link
                  :to_="{ name: 'apps-events-view', params: { id: data.item.id } }"
                  :to="{ name: 'apps-events-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">@{{ data.item.serial }}</small>
              </b-media>
            </template>

            <template #cell(description)="data">

              <div class="text-nowrap">
                <span
                  v-if="data.item.popup && data.item.popup.length"
                  class="align-text-top text-primary"
                ><a
                  @click="customPopup(data.item.popup)"
                >{{ data.item.description }}</a>
                </span>
                <span
                  v-else-if="data.item.popup"
                  class="align-text-top text-danger"
                >{{ data.item.description }}</span>
                <span
                  v-else
                  class="align-text-top"
                >{{ data.item.description && data.item.description.desc && data.item.description.desc[$i18n.locale] ? data.item.description.desc[$i18n.locale] : "" }}<br><small> {{ data.item.description && data.item.description.details && data.item.description.details[$i18n.locale]? data.item.description.details[$i18n.locale] : "" }} </small></span>
              </div>

            </template>

            <!-- Column: Object  -->
            <template #cell(machineId)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveEventMachineText(data.item.machineId) }}</span>
              </div>
            </template>

            <!-- Column: Company -->
            <template #cell(company_id)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ resolveEventCompanyText(data.item.companyId) }}</span>
              </div>
            </template>

            <template #cell(type)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">
                  <feather-icon
                    :icon="rowClass(data.item, 'icon')"
                    :class="rowClass(data.item, 'class')"
                    size="18"
                  />
                </span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveEventStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--b-dropdown-item :to="{ name: 'apps-events-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item-->

                <b-dropdown-item :to="{ name: 'apps-events-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteEvent(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('common.showing') }} {{ dataMeta.from }} {{ $t('common.to') }} {{ dataMeta.to }} {{ $t('common.of') }} {{ dataMeta.of }} {{ $t('common.entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalEvents"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="ClockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('common.durationEvents') }}</span>
        </template>

        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perDurationEventsPage"
                  :options="perPageDurationEventsOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <!--b-form-input
                      v-model="searchReportQuery"
                      class="d-inline-block mr-1"
                      :placeholder="$t('common.search')"
                    /-->
                  <!--b-button
                  variant="primary"
                  @click="isAddNewSaleSidebarActive = true"
                >
                  <span class="text-nowrap">Add Sale</span>
                </b-button-->
                  <vue-excel-xlsx
                    :data="tableDurationRows"
                    :columns="eventsDurationSheetColumns"
                    :file-name="'events_duration'"
                    :file-type="'xlsx'"
                    :sheet-name="'Events duration'"
                    class="btn btn-link"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </vue-excel-xlsx>
                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchDurationEventsData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refDurationEventsListTable"
            v-model="tableDurationRows"
            class="position-relative"
            :items="fetchDurationEvents"
            responsive
            :fields="tableDurationEventsColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >

            <!-- Column: Status -->
            <template #cell(type)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">
                  <feather-icon
                    :icon="rowClass(data.item, 'icon')"
                    :class="rowClass(data.item, 'class')"
                    size="18"
                  />
                </span>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <!--              <b-col-->
              <!--                cols="12"-->
              <!--                sm="6"-->
              <!--                class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
              <!--              >-->
              <!--                <span class="text-muted">{{ $t('common.showing') }} {{ dataReportMeta.from }} {{ $t('common.to') }} {{ dataReportMeta.to }} {{ $t('common.of') }} {{ dataReportMeta.of }} {{ $t('common.entries') }}</span>-->
              <!--              </b-col>-->
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentDurationEventsPage"
                  :total-rows="totalDurationEvents"
                  :per-page="perDurationEventsPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('common.totalEvents') }}</span>
        </template>

        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('common.show') }}</label>
                <v-select
                  v-model="perTotalEventsPage"
                  :options="perPageTotalEventsOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('common.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <!--b-form-input
                      v-model="searchReportQuery"
                      class="d-inline-block mr-1"
                      :placeholder="$t('common.search')"
                    /-->
                  <!--b-button
                  variant="primary"
                  @click="isAddNewSaleSidebarActive = true"
                >
                  <span class="text-nowrap">Add Sale</span>
                </b-button-->

                  <vue-excel-xlsx
                    :data="tableTotalEventsRows"
                    :columns="eventsTotalSheetColumns"
                    :file-name="'events_total'"
                    :file-type="'xlsx'"
                    :sheet-name="'Total events'"
                    class="btn btn-link"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </vue-excel-xlsx>

                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="refetchTotalEventsData"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refTotalEventsListTable"
            v-model="tableTotalEventsRows"
            class="position-relative"
            :items="fetchTotalEvents"
            responsive
            :fields="tableTotalEventsColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >

            <!-- Column: Status -->
            <template #cell(type)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">
                  <feather-icon
                    :icon="rowClass(data.item, 'icon')"
                    :class="rowClass(data.item, 'class')"
                    size="18"
                  />
                </span>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <!--              <b-col-->
              <!--                cols="12"-->
              <!--                sm="6"-->
              <!--                class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
              <!--              >-->
              <!--                <span class="text-muted">{{ $t('common.showing') }} {{ dataReportMeta.from }} {{ $t('common.to') }} {{ dataReportMeta.to }} {{ $t('common.of') }} {{ dataReportMeta.of }} {{ $t('common.entries') }}</span>-->
              <!--              </b-col>-->
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentTotalEventsPage"
                  :total-rows="totalTotalEvents"
                  :per-page="perTotalEventsPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-tab>
    </b-tabs>
  </div></template>

<script>
import {
  // BCard, BRow, BCol, BFormInput,
  BButton,
  // BTable, BMedia, BAvatar, BLink,
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import Vue from 'vue'
import store from '@/store'
import useEventsList from '@/views/apps/events/events-list/useEventsList'
import EventsListFilters from './EventsListFilters.vue'
import eventStoreModule from '../eventStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    EventsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    rowClass(item, type) {
      let color = ''
      if (item.description && item.description.color) color = item.description.color
      // console.log(item, color)
      let icon = ''
      let cls = ''
      if (color /* || true */) {
        if (color === 'success') {
          icon = 'CheckSquareIcon'
          cls = 'text-success'
        } else if (color === 'warning') {
          icon = 'AlertTriangleIcon'
          cls = 'text-warning'
        } else if (color === 'danger') {
          icon = 'XSquareIcon'
          cls = 'text-danger'
        } else /* if (color === 'light') */ {
          icon = 'InfoIcon'
          cls = 'text-dark'
        }
      }
      // console.log(icon, cls)
      return type === 'icon' ? icon : cls
    },
  },
  // setup(props) {
  setup() {
    // console.log(props)

    const USER_APP_STORE_MODULE_NAME = 'app-event'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, eventStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewEventSidebarActive = ref(false)

    /*

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    /*
    const companyOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchEvents,
      fetchEventsFile,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventListTable,
      refetchData,

      // UI
      resolveEventRoleVariant,
      resolveEventRoleIcon,
      resolveEventStatusVariant,
      resolveEventCompanyText,
      resolveEventMachineText,

      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,

      machineOptions,
      objectOptions,
      companyOptions,

      deleteEvent,

      rangePicker,

      tableDurationEventsColumns,
      tableTotalEventsColumns,
      tableDurationRows,
      tableTotalEventsRows,
      perDurationEventsPage,
      perTotalEventsPage,
      fetchDurationEvents,
      fetchTotalEvents,
      totalDurationEvents,
      totalTotalEvents,
      refDurationEventsListTable,
      refTotalEventsListTable,
      perPageTotalEventsOptions,
      perPageDurationEventsOptions,
      currentDurationEventsPage,
      currentTotalEventsPage,
      refetchTotalEventsData,
      refetchDurationEventsData,

    } = useEventsList()

    const customPopup = item => {
      // console.log(item)

      Vue.swal({
        title: 'Z-REPORT',
        // eslint-disable-next-line global-require
        html: item,
        icon: 'none',
        // imageWidth: 350,
        // imageHeight: 350,
        // imageAlt: 'Custom image',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
    const eventsDurationSheetColumns = computed(() => tableDurationEventsColumns.value.filter(({ key }) => key !== 'type').map(({ key, label }) => ({
      label,
      field: key,
    })))

    const eventsTotalSheetColumns = computed(() => tableTotalEventsColumns.value.filter(({ key }) => key !== 'type').map(({ key, label }) => ({
      label,
      field: key,
    })))
    return {
      eventsDurationSheetColumns,
      eventsTotalSheetColumns,
      // Sidebar
      isAddNewEventSidebarActive,

      fetchEvents,
      fetchEventsFile,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveEventRoleVariant,
      resolveEventRoleIcon,
      resolveEventStatusVariant,
      resolveEventCompanyText,
      resolveEventMachineText,

      machineOptions,
      companyOptions,
      objectOptions,
      statusOptions,

      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,
      statusFilter,

      deleteEvent,
      customPopup,

      rangePicker,

      tableDurationEventsColumns,
      tableTotalEventsColumns,
      tableDurationRows,
      tableTotalEventsRows,
      perDurationEventsPage,
      perTotalEventsPage,
      fetchDurationEvents,
      fetchTotalEvents,
      totalDurationEvents,
      totalTotalEvents,
      refDurationEventsListTable,
      refTotalEventsListTable,
      perPageTotalEventsOptions,
      perPageDurationEventsOptions,
      currentDurationEventsPage,
      currentTotalEventsPage,
      refetchTotalEventsData,
      refetchDurationEventsData,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
    table .npd_15_0 {
        padding-left: 15px;
        padding-right: 5px;
    }
    table .npd_0_15 {
        padding-left: 5px;
        padding-right: 15px;
    }
    table .npd_0_0 {
        padding-left: 5px;
        padding-right: 5px;
    }
    table .npd_0_ {
        padding-left: 5px;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
