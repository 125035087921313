import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useEventsList() {
  // console.log(router.currentRoute.params)

  // Use toast
  const toast = useToast()

  const refEventListTable = ref(null)
  const tableDurationEventsTemplate = [
    {
      key: 'machine_name',
      label: i18n.t('components.machine'),
      formatter: title,
    },
    {
      key: 'type',
      label: '',
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'description',
      label: i18n.t('telemetry.description'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'event_time',
      label: i18n.t('common.startTime'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'finished_at',
      label: i18n.t('common.endTime'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'duration',
      label: i18n.t('common.duration'),
      formatter: title,
      sortable: false,
    },
  ]

  const tableTotalEventsTemplate = [
    {
      key: 'machine_name',
      label: i18n.t('components.machine'),
      formatter: title,
    },
    {
      key: 'type',
      label: '',
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'description',
      label: i18n.t('telemetry.description'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'duration',
      label: i18n.t('common.duration'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'count',
      label: i18n.t('common.total'),
      formatter: title,
      sortable: false,
    },
  ]
  // Table Handlers
  const tableColumns = [
    { key: 'event_time', label: i18n.t('telemetry.time'), sortable: true },
    {
      key: 'type',
      label: '',
      sortable: true,
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'event',
      label: i18n.t('telemetry.event'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'description',
      label: i18n.t('telemetry.description'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'machine_name',
      label: i18n.t('telemetry.machine'),
      formatter: title,
      sortable: true,
    },
  ]
  const refDurationEventsListTable = ref(null)
  const refTotalEventsListTable = ref(null)
  const totalDurationEvents = ref(0)
  const totalTotalEvents = ref(0)
  const currentDurationEventsPage = ref(1)
  const perPageDurationEventsOptions = [10, 50, 250, 500]
  const perDurationEventsPage = ref(10)

  const currentTotalEventsPage = ref(1)
  const perPageTotalEventsOptions = [10, 50, 250, 500]
  const perTotalEventsPage = ref(10)

  const tableDurationEventsColumns = ref([...tableDurationEventsTemplate])
  const tableTotalEventsColumns = ref([...tableTotalEventsTemplate])
  const tableDurationRows = ref([])
  const tableTotalEventsRows = ref([])

  const refetchDurationEventsData = () => {
    refDurationEventsListTable.value.refresh()
  }

  const refetchTotalEventsData = () => {
    refTotalEventsListTable.value.refresh()
  }

  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('event_time')
  const isSortDirDesc = ref(true)
  const rangePicker = ref([moment().set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString(), moment().set({
    hour: 23, minute: 59, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString()])

  if (router.currentRoute.params.machineFilter) {
    store.commit('appConfig/UPDATE_MACHINE_FILTER', router.currentRoute.params.machineFilter)
    store.commit('appConfig/UPDATE_OBJECT_FILTER', null)
    store.commit('appConfig/UPDATE_COMPANY_FILTER', null)
  }

  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEventListTable.value ? refEventListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const fetchEvents = (ctx, callback) => {
    store
      .dispatch('app-event/fetchEvents', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        company: companyFilter.value,
        object: objectFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        lang: i18n.locale,
      })
      .then(response => {
        const { events, total } = response.data

        callback(events)
        totalEvents.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching events list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchDurationEvents = (ctx, callback) => {
    store
      .dispatch('app-event/fetchDurationEvents', {
        perPage: perDurationEventsPage.value,
        page: currentDurationEventsPage.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value, // .join(' '),
        lang: i18n.locale,
      })
      .then(({ data: { total = 0, data = [] } }) => {
        totalDurationEvents.value = total
        tableDurationRows.value = data
        console.log(data)
        callback(data)
      })
    // .catch(err => {
      .catch(() => {
        // console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching duration events list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchTotalEvents = (ctx, callback) => {
    store
      .dispatch('app-event/fetchTotalEvents', {
        perPage: perTotalEventsPage.value,
        page: currentTotalEventsPage.value,
        machine: machineFilter.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value, // .join(' '),
        lang: i18n.locale,
      })
      .then(({ data: { total = 0, data = [] } }) => {
        totalTotalEvents.value = total
        tableTotalEventsRows.value = data
        callback(data)
      })
    // .catch(err => {
      .catch(() => {
        // console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching duration events list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refEventListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchData()
  })

  watch([perDurationEventsPage, currentDurationEventsPage, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchDurationEventsData()
  })

  watch([perTotalEventsPage, currentTotalEventsPage, machineFilter, companyFilter, objectFilter, statusFilter, rangePicker], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchTotalEventsData()
  })

  const fetchEventsFile = (type = 'xlsx') => {
    store
      .dispatch('app-event/fetchEvents', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        company: companyFilter.value,
        object: objectFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value,
        lang: i18n.locale,
        type,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Events.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteEvent = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Event <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-event/deleteEvent', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Event ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Event delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const machineOptions = ref([])
  store.dispatch('app-event/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const objectOptions = ref([])
  store.dispatch('app-event/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-event/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEventStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveEventCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveEventMachineText = machine => {
    if (!machine) {
      return ''
    }
    try {
      return machineOptions.value.find(o => o.value === machine).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchEvents,
    fetchEventsFile,
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEventListTable,

    resolveEventStatusVariant,
    resolveEventCompanyText,
    resolveEventMachineText,
    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,
    statusFilter,

    companyOptions,
    objectOptions,
    machineOptions,

    deleteEvent,

    rangePicker,

    tableDurationEventsColumns,
    tableTotalEventsColumns,
    tableDurationRows,
    tableTotalEventsRows,
    perDurationEventsPage,
    perTotalEventsPage,
    fetchDurationEvents,
    fetchTotalEvents,
    totalDurationEvents,
    totalTotalEvents,
    refDurationEventsListTable,
    refTotalEventsListTable,
    perPageTotalEventsOptions,
    perPageDurationEventsOptions,
    currentDurationEventsPage,
    currentTotalEventsPage,
    refetchTotalEventsData,
    refetchDurationEventsData,

  }
}
