import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDurationEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/event/duration-events', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTotalEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/event/duration-events-total', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/event/events', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/event/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/event/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/event/events', { event: eventData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMachines() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/machine/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
